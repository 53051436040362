import React from 'react'

export const NoDataDashboard = () => {
  return (
   <div className="dashboard-main">
            <label className="dash-heading">NO DATA AVAILABLE FOR THE USER</label>
            <label className="dash-content">Please select a Report Type<br/> and date to view data</label>
        </div>
  )
}
